<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.app {
  width: 100vw;
  height: 100vh;
  background-color: #091339;
  overflow: hidden;
}
</style>
