import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view';

import './assets/scss/style.scss';
import './assets/icon/iconfont.css'

const app = createApp(App)
app.use(dataV)
app.use(store)
app.use(router)
app.mount('#app')
