import {createStore} from 'vuex'

export default createStore({
    state: {
        config: {
            "name": "海上丝绸之路",
            "beian": "",
            "cdnurl": "",
            "version": 1657678143,
            "timezone": "Asia/Shanghai",
            "forbiddenip": "",
            "languages": {
                "backend": "zh-cn",
                "frontend": "zh-cn"
            },
            "fixedpage": "general/config",
            "configgroup": {
                "data_show": "数据大屏中间",
                "data_show_left": "数据大屏左侧",
                "data_show_right": "数据大屏右侧"
            },
            "data_show_title": "海上丝绸之路",
            "data_show_title2": "技术由浦沿科技提供",
            "data_show_left_title": "NCFI 宁波出口集装箱运价指数",
            "dp_left_data_1": {
                "宁波·欧洲": "5917.47",
                "宁波·地西": "5778.26",
                "宁波·地东": "4539.34",
                "宁波·美西": "6720.63",
                "宁波·美东": "3461.29",
                "宁波·中东": "3796.75",
                "宁波·南美西": "4579.74",
                "宁波·南美东": "5868.88",
                "宁波·印巴": "6581.87",
                "宁波·红海": "4903.35",
                "宁波·澳新": "7553.43",
                "宁波·日本关东": "635.27",
                "宁波·日本关西": "612.15",
                "宁波·秦越": "3446.91",
                "宁波·新马": "5070.07",
                "宁波·菲律宾": "116",
                "宁波·黑海": "4500.89",
                "宁波·西非": "2567",
                "宁波·南非": "5638.19",
                "宁波·东非": "2493.35",
                "宁波·北非": "4741.28"
            },
            "dp_left_data_2": {
                "宁波·欧洲": "4123.39",
                "宁波·地西": "4823.5",
                "宁波·地东": "3874.99",
                "宁波·美西": "4286.95",
                "宁波·美东": "3360.27",
                "宁波·中东": "3249.19",
                "宁波·南美西": "3680.15",
                "宁波·南美东": "4532.85",
                "宁波·印巴": "5036.96",
                "宁波·红海": "4261.52",
                "宁波·澳新": "5004.78",
                "宁波·日本关东": "1241.33",
                "宁波·日本关西": "1249.77",
                "宁波·秦越": "2079.66",
                "宁波·新马": "2861.3",
                "宁波·菲律宾": "121.96",
                "宁波·黑海": "4057.89",
                "宁波·西非": "2061.47",
                "宁波·南非": "5112.59",
                "宁波·东非": "1898.56",
                "宁波·北非": "4193.9"
            },
            "dp_left_data_3": {
                "宁波·欧洲": "4048.49",
                "宁波·地西": "4803.39",
                "宁波·地东": "3812.81",
                "宁波·美西": "4215.83",
                "宁波·美东": "3309.92",
                "宁波·中东": "3152.43",
                "宁波·南美西": "3707.23",
                "宁波·南美东": "5043.48",
                "宁波·印巴": "5121.01",
                "宁波·红海": "4539.25",
                "宁波·澳新": "5011.24",
                "宁波·日本关东": "1185.84",
                "宁波·日本关西": "1143.59",
                "宁波·秦越": "1804.73",
                "宁波·新马": "2953.63",
                "宁波·菲律宾": "115.58",
                "宁波·黑海": "3914.47",
                "宁波·西非": "2109.94",
                "宁波·南非": "5060.81",
                "宁波·东非": "1994.5",
                "宁波·北非": "4145.57"
            },
            "data_show_left_vice_title_2_2": "比上期涨跌（%）",
            "data_show_left_vice_title_3": "上期（202106）",
            "data_show_left_vice_title_4": "本期（202207）",
            "data_show_left_vice_title_5": "NCFI-航线-综合指数",
            "data_show_left_echarts_bottom": {
                "1月": "1500",
                "2月": "2000",
                "3月": "1980",
                "4月": "4980",
                "5月": "1800",
                "6月": "2341",
                "7月": "2100",
                "8月": "2341",
                "9月": "3123",
                "10月": "4513",
                "11月": "4231",
                "12月": "2123"
            },
            "data_show_center_vice_title_1": "202207",
            "data_show_center_vice_data_1": {
                "综合企业信心指数": "111.11",
                "集装箱企业信心指数": "117.47",
                "干散货企业信心指数": "110",
                "液散货企业信心指数": "113",
                "件杂货企业信心指数": "106.67"
            },
            "data_show_center_vice_title_2": "STI海上丝绸贸易指数",
            "data_show_center_vice_data_2_g": [
                "进出口",
                "进口",
                "出口"
            ],
            "data_show_center_vice_data_2_0": {
                "202201": "98.12",
                "202202": "92.15",
                "202203": "82.10",
                "202204": "90.11",
                "202205": "92.12",
                "202206": "94.19",
                "202207": "92.45",
                "202208": "95.12",
                "202209": "85.15",
                "202210": "85.12",
                "202211": "85.19",
                "202212": "93.45"
            },
            "data_show_center_vice_data_2_1": {
                "202201": "98.12",
                "202202": "92.15",
                "202203": "82.10",
                "202204": "90.11",
                "202205": "92.12",
                "202206": "94.19",
                "202207": "92.45",
                "202208": "95.12",
                "202209": "85.15",
                "202210": "85.12",
                "202211": "85.19",
                "202212": "93.45"
            },
            "data_show_center_vice_data_2_2": {
                "202201": "98.12",
                "202202": "92.15",
                "202203": "82.10",
                "202204": "90.11",
                "202205": "92.12",
                "202206": "94.19",
                "202207": "92.45",
                "202208": "95.12",
                "202209": "85.15",
                "202210": "85.12",
                "202211": "85.19",
                "202212": "93.45"
            },
            "data_show_center_vice_title_3": "总体",
            "data_show_center_vice_date_3_1": [
                "进出口",
                "进口",
                "出口"
            ],
            "data_show_center_vice_title_4": "上上期",
            "data_show_center_vice_title_6": "本期",
            "data_show_center_vice_title_5": "上期",
            "data_show_center_vice_date_4_1": [
                "初值",
                "终值"
            ],
            "data_show_center_vice_date_5_1": [
                "初值",
                "终值"
            ],
            "data_show_center_vice_date_6_1": [
                "初值",
                "终值"
            ],
            "data_show_center_vice_title_7": "终值",
            "data_show_center_vice_date_7": {
                "亚洲": "164.75",
                "欧洲": "186.57",
                "非洲": "187.62",
                "北美洲": "157.71",
                "拉丁美洲": "245.24",
                "大洋洲": "184.8"
            },
            "center_tab_data_jk": {
                "202201": "108.66,108.66",
                "202202": "156.04,156.04",
                "202203": "127.88,127.88",
                "202204": "111.88,122.88",
                "202205": "147.81,147.81",
                "202206": "123.12,123.12",
                "202207": "167.15,167.15",
                "202208": "112.33,112.33",
                "202209": "153.11,153.12",
                "202210": "123.12,153.11",
                "202211": "163.13,153.14",
                "202212": "173.14,153.15"
            },
            "center_tab_data_jck": {
                "202201": "108.66,108.66",
                "202202": "156.04,156.04",
                "202203": "127.88,127.88",
                "202204": "111.88,122.88",
                "202205": "147.81,147.81",
                "202206": "123.12,123.12",
                "202207": "167.15,167.15",
                "202208": "112.33,112.33",
                "202209": "153.11,153.12",
                "202210": "123.12,153.11",
                "202211": "163.13,153.14",
                "202212": "173.14,153.15"
            },
            "center_tab_data_ck": {
                "202201": "108.66,108.66",
                "202202": "156.04,156.04",
                "202203": "127.88,127.88",
                "202204": "111.88,122.88",
                "202205": "147.81,147.81",
                "202206": "123.12,123.12",
                "202207": "167.15,167.15",
                "202208": "112.33,112.33",
                "202209": "153.11,153.12",
                "202210": "123.12,153.11",
                "202211": "163.13,153.14",
                "202212": "173.14,153.15"
            },
            "data_show_right_title": "NPI宁波港口指数",
            "data_show_right_vice_title_1": "港口企业信息指数",
            "data_show_right_vice_data_1": {
                "202201": "98.12",
                "202202": "92.15",
                "202203": "82.10",
                "202204": "90.11",
                "202205": "92.12",
                "202206": "94.19",
                "202207": "92.45",
                "202208": "95.12",
                "202209": "85.15",
                "202210": "85.12",
                "202211": "85.19",
                "202212": "93.45"
            },
            "data_show_right_vice_title_2": "港口景气指数",
            "data_show_right_echarts_1": {
                "1月": "1500",
                "2月": "2000",
                "3月": "1980",
                "4月": "4980",
                "5月": "1800",
                "6月": "2341",
                "7月": "2100",
                "8月": "2341",
                "9月": "3123",
                "10月": "4513",
                "11月": "4231",
                "12月": "2123"
            },
            "data_show_right_vice_title_3": "港口企业信心指数",
            "data_show_right_echarts_2": {
                "1月": "1500",
                "2月": "2000",
                "3月": "1980",
                "4月": "4980",
                "5月": "1800",
                "6月": "2341",
                "7月": "2100",
                "8月": "2341",
                "9月": "3123",
                "10月": "4513",
                "11月": "4231",
                "12月": "2123"
            }
        }
    },
    mutations: {
        SET_CONFIG: (state, configData) => {
            state.config = configData
        },
    },
    actions: {
        setConfig({commit}, device) {
            commit('SET_CONFIG', device)
        },
    },
    getters: {
        config: function (state) {
            return state.config
        }
    },
    modules: {}
})
